import React from 'react';
import Modal from "react-modal";
import ContactList from "../../pages/Contacts/Contact";
import {useTranslation} from "react-i18next";
import {useAssignContactsMutation} from "../../api/ProjectApi";

const AssignContactModal = ({isOpen, onRequestClose, projectId}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = React.useState([]);
    const [assignContacts, {isLoading}] = useAssignContactsMutation();

    const containerElement = ({children}) => <div className="col-12">{children}</div>;

    const handleOnClick = async event => {
        event.preventDefault();
        await assignContacts({id: projectId, contactIds: selected}).unwrap();
    };

    const handleOnClickRow = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <Modal
            isOpen={isOpen} className="modal modal-contact" overlayClassName="modal-overlay"
            contentLabel="Contact Modal" onRequestClose={onRequestClose}>
            <div className="container">
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <div className='container-shadow form row'>
                            <div className='col-12 form-heading'>
                                <h3>
                                    Assigner un contact
                                </h3>
                                <button className='btn-close' onClick={onRequestClose}/>
                            </div>
                            <ContactList contentElement={containerElement} useRowSelect excludedProjectId={projectId}
                                         handleOnClickRow={handleOnClickRow} isSelected={isSelected} pageSize={5}/>
                            <div className='col-12 btn-group text-center'>
                                <button className='btn-ternary' disabled={selected.length === 0 || isLoading}
                                        onClick={handleOnClick}>
                                    {t('contact.assign_contacts', {count: selected.length})}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AssignContactModal;