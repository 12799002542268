import {api} from "../app/SynappApi";
import {TAGS} from "../config/constants";
import {getProvidedTags, objectToQueryStrings} from "../utils/query-utils";

const contactApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getContacts: builder.query({
            query: params => `/api/services/app/Contact/GetAll${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: result => getProvidedTags(result.items, TAGS.CONTACTS)
        }),
        getContact: builder.query({
            query: (id) => `api/services/app/Contact/Get?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.CONTACTS, id}],
        }),
        getContactEnterprisesSelectItems: builder.query({
            query: () => 'api/services/app/Contact/GetContactEnterprises',
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.CONTACT_ENTERPRISE_LIST_ITEMS, true)
        }),
        getContactSelectItems: builder.query({
            query: (isClient) => `api/services/app/Contact/GetAllSelectItems?isClient=${isClient || false}`,
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.CONTACT_LIST_ITEMS, true)
        }),
        createContact: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Contact/Create',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.CONTACTS, id: TAGS.DEFAULT}, {
                type: TAGS.CONTACT_LIST_ITEMS,
                id: TAGS.DEFAULT
            }],
        }),
        updateContact: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Contact/Update',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.CONTACTS, id}, {type: TAGS.CONTACT_LIST_ITEMS, id}],
        }),
        deleteContact: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/Contact/Delete?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.CONTACTS, id}, {type: TAGS.CONTACT_LIST_ITEMS, id}],
        })
    })
});

export const {
    useGetContactsQuery,
    useGetContactQuery,
    useGetContactEnterprisesSelectItemsQuery,
    useGetContactSelectItemsQuery,
    useCreateContactMutation,
    useUpdateContactMutation,
    useDeleteContactMutation
} = contactApi;